
import { defineComponent } from 'vue'
import { AirlineManageGates as ApplyIcon, Settings as SettingsIcon, Blog as BlogIcon, Chat as ChatIcon, Microphone as MicrophoneIcon } from '@vicons/carbon'
import Info from '../../components/Info.vue'

export default defineComponent({
	components: {
		Info,
		ApplyIcon,
		SettingsIcon,
		BlogIcon,
		ChatIcon,
		MicrophoneIcon
	},
	setup() {
		return {}
	},
	methods: {
		router: function (goto: string) {
			this.$router.push(goto)
		},
	},
})
